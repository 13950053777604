@import "./colors";
@import "./_fonts.scss";


.review_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  max-width: 1000px;
  margin: 40px auto;
  max-width: 100%;
  padding: 20px;
  border-radius: 10px;
  background-color: transparent
}

.review-card {
  background: #fff;
  margin: 10px 0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  max-width: 350px;
  width: 300px;
}


.review-card blockquote {
  margin: 0;
  padding: 0;
  //font-style: italic;
  font-size: larger;
  color: #555;
  text-align: left;
  overflow: hidden;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.review-card footer {
  margin-top: 20px;
  text-align: right;
}

.review-card cite {
  font-size: 0.9em;
  color: #333;
}