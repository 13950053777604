@import "./colors";
@import "./_fonts.scss";

/* About.css */
.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-top: 50px;
  background-color: white;
  //min-height: 500px;

  h3 {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.about-image-container {
  flex-shrink: 0;
  margin-right: 20px;


}

.about-image {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  object-fit: cover;
}

.about-description {
  max-width: 900px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.about-description p {
  margin: 0;
  text-align: left;
}

/* Media query for screens smaller than 800px */
@media (max-width: 900px) {
  .about-container {
    flex-direction: column;
    align-items: center;
  }

  .about-image-container {
    margin-right: 0;
    margin-bottom: 20px;
  }
}