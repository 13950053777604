@import "./colors";
@import "./_fonts.scss";

.reviews_container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  max-width: 1000px;
  margin: 40px auto;

  button {
    max-width: 300px;
  }
}