@import "./colors";
@import "./_fonts.scss";


.Header {
  display: flex;
  min-height: 300px;
  background-color: $base_color_light;
  color: $base_font_dark;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .titles {
    text-align: center;

    h1 {
      font-size: 100px;
      font-family: "Major Mono Display", monospace;
      font-weight: 400;
      font-style: normal;
      margin-bottom: 20px;
    }

    h2 {
      font-family: "Red Hat Display", sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: 30px;
    }
  }

  .header_image {
    margin: 30px;
    width: 200px; // Set the desired width
    height: 200px; // Set the desired height
    border-radius: 50%;
    border: 2px solid $base_font_dark; // Replace $base_font_dark with your desired color variable or value
  }

}

/* Media query for screens smaller than 800px */
@media (max-width: 650px) {
  .Header {
    .titles {
      h1 {
        font-size: 70px;
      }

      h2 {
        font-size: 30px;
      }
    }
  }
}

@media (max-width: 425px) {
  .Header {
    .titles {
      h1 {
        font-size: 50px;
      }

      h2 {
        font-size: 20px;
      }
    }
  }
}