@import url("https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Major+Mono+Display&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap");
/* makes sizing simpler */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* remove default spacing */
/* force styling of type through styling, rather than elements */
* {
  margin: 0;
  padding: 0;
}

/* dark mode user-agent-styles */
/* improves punctuation in supported browsers */
html {
  color-scheme: dark light;
  hanging-punctuation: first last;
  font-family: "Red Hat Display", sans-serif;
}

/* min body height */
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  min-height: 100svh;
  color: rgb(70, 70, 70);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: Arial, sans-serif;
}

#root {
  width: 100%;
  flex: 1;
}

/* responsive images/videos */
img,
picture,
svg,
video {
  display: block;
  max-width: 100%;
}

/* Improved heading in supported browsers */
h1,
h2,
h3,
h4,
h5,
h6 {
  text-wrap: balance;
}

/* improve readability with max-width on paragraphs and lists */
/* prevent orphans in supported browsers */
p,
li {
  max-width: var(--p-max-width, 107ch);
  text-wrap: pretty;
}

p {
  font-size: 20px;
}

body {
  background-color: rgb(241, 241, 241);
}