@import "./colors";
@import "./_fonts.scss";

.introduction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px auto;

  p {
    padding: 10px 20px;
    //text-align: justify;
  }
}

.introduction_gallery {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 40px;
  max-width: 1500px;
}

.introduction_card {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin: 20px;

  img {
    width: 100%;
    object-fit: cover;
  }

  .card_text_container {
    padding: 10px 20px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #3f5068;
    max-height: 150px;
    min-height: 150px;

    p,
    h4 {
      margin-top: 20px;
      padding-bottom: 10px;
      text-align: center;
      color: white;
    }

    p {
      font-size: 16px;
      padding: 0px;
    }
  }
}