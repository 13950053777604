@import "./colors";

.rdv-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-top: 50px;
  background-color: white;
  min-height: 300px;

  .rdv {
    display: flex;
    flex-direction: column;

    justify-content: left;
    align-items: flex-start;
  }
}