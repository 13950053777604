@import "./colors";
@import "./_fonts.scss";

.previewform_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  max-width: 1000px;
  margin: 40px auto;
  padding: 20px;
  border-radius: 10px;
  background-color: $base_color_light;
  color: $base_font_dark;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, .25);
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.name_field {
  width: 100%;
  margin-top: 30px;

  input[type="text"],
  input[type="number"],
  textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    background-color: #ffffff;
    color: black;
  }
}

.reviex_field {
  width: 100%;

  input[type="text"],
  input[type="number"],
  textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    min-height: 200px;
    background-color: #ffffff;
    color: black;
  }
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}



button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  background-color: #f1f1f1;
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
}

li strong {
  display: block;
  margin-bottom: 5px;
}

/* Styles for the modal overlay */
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styles for the modal content */
.Modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  //background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}