@import "./colors";

.services-container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  max-width: 1000px;
  margin: 40px auto;

  p {
    padding: 10px 20px;
    text-align: left;
  }

  h3 {
    text-align: center;
  }

  h4 {
    text-align: left;
  }

  .services-container h2,
  .services-container h3,
  .services-container h4 {
    color: #333;
  }

  .service-section {
    margin-bottom: 20px;
    padding-left: 20px;
  }

  .service-section h3,
  .service-section h4 {
    margin-top: 0;
  }

  .services-container p {
    margin: 10px 0;
    line-height: 1.6;
  }

  .services-container p strong {
    display: block;
    margin-top: 10px;
  }

  .deroulement {
    padding-left: 20px;
  }

  .less-bold {
    font-weight: 500;
  }
}